import React, { useMemo, useRef, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from '@reach/router'
import { Button, Input, Modal } from '@refera/ui-web'
import { Grid } from '@mui/material'
import { Controller, FormProvider, useForm } from 'react-hook-form'

import { getOriginalBudget } from '_modules/budget/selectors'
import { updateBudget, UPDATE_BUDGET } from '_modules/budget/actions'
import useFetchCall from '_hooks/use-fetch-call'
import { parseToNumber, stringifyBoolean, stringifyNumber } from '_utils/helpers'

import Radio from './radio'
import useStyles, { inputStyle } from './styles'

const EditPreApprovedBudgetModal = ({ handleClose, handleConfirm, isOpen, shouldSendBudget }) => {
  const styles = useStyles()
  const dispatch = useDispatch()
  const { budgetId } = useParams()
  const budget = useSelector(getOriginalBudget(budgetId))?.toJS()

  const formatNumberValue = value => {
    const stringifiedNumber = stringifyNumber(value)

    if (stringifiedNumber === '0') {
      return 'Nenhum'
    }

    return stringifiedNumber
  }

  const defaultValues = useMemo(
    () => ({
      acceptsInstallment: stringifyBoolean(budget?.acceptsInstallment),
      installments: formatNumberValue(budget?.installments),
      acceptsWarranty: stringifyBoolean(budget?.acceptsWarranty),
      warrantyMonths: formatNumberValue(budget?.warrantyMonths),
      visitHappened: stringifyBoolean(budget?.visitHappened),
    }),
    [budget]
  )

  const formRef = useRef()
  const methods = useForm({ defaultValues })
  const { control, errors, handleSubmit, setValue, watch } = methods
  const acceptsWarrantyWatch = watch('acceptsWarranty')

  const isWarrantyMonthsDisabled = useMemo(
    () => acceptsWarrantyWatch === 'false',
    [acceptsWarrantyWatch]
  )

  const radioOptions = [
    {
      label: 'Não',
      value: 'false',
    },
    {
      label: 'Sim',
      value: 'true',
    },
  ]

  const minValue = value => {
    if (value === 'Nenhum') {
      return true
    }

    return Number(value) > 0 || "Valor deve ser maior que '0'"
  }

  const warrantyMonthsInputRules = useMemo(() => {
    if (isWarrantyMonthsDisabled) {
      return {
        validate: {
          minValue,
        },
      }
    }

    return {
      required: 'Campo obrigatório',
      validate: {
        minValue,
      },
    }
  }, [isWarrantyMonthsDisabled])

  const handleSave = useCallback(
    () => formRef?.current?.dispatchEvent(new Event('submit', { cancelable: true })),
    [formRef?.current]
  )

  const handleInputChange = useCallback(event => {
    const { name, value } = event.target

    const sanitizedValue = parseToNumber(value)
    setValue(name, sanitizedValue)
  }, [])

  const handleRadioChange = useCallback((event, value, name) => {
    const relatedField = {
      acceptsInstallment: 'installments',
      acceptsWarranty: 'warrantyMonths',
    }

    if (value === 'false') {
      setValue(relatedField[name], 'Nenhum')
    } else {
      setValue(relatedField[name], '2')
    }

    setValue(name, value)
  }, [])

  const onSubmit = useCallback(
    handleSubmit(data => {
      const payload = Object.keys(data).reduce((acc, key) => {
        if (data[key] === 'true') {
          return {
            ...acc,
            [key]: true,
          }
        }

        if (data[key] === 'false') {
          return {
            ...acc,
            [key]: false,
          }
        }

        if (data[key] === 'Nenhum') {
          return {
            ...acc,
            [key]: 0,
          }
        }

        return {
          ...acc,
          [key]: data[key],
        }
      }, {})

      dispatch(
        updateBudget(budget?.serviceOrder, budgetId, {
          ...payload,
          released: shouldSendBudget,
        })
      )
    }),
    [dispatch, budget, budgetId, shouldSendBudget]
  )

  const handleUpdateBudgetSuccess = useCallback(() => {
    if (isOpen) {
      handleConfirm?.()
      handleClose()
    }
  }, [handleClose, handleConfirm, isOpen])

  useFetchCall(UPDATE_BUDGET.ACTION, handleUpdateBudgetSuccess)

  const actionsButtons = useMemo(
    () => (
      <Grid container spacing={8} direction="row" justifyContent="flex-end" alignItems="center">
        <Grid item>
          <Button onClick={handleClose} color="red" variant="secondary">
            Cancelar
          </Button>
        </Grid>
        <Grid item>
          <Button type="submit" onClick={handleSave}>
            {shouldSendBudget ? 'Salvar e Enviar' : 'Salvar'}
          </Button>
        </Grid>
      </Grid>
    ),
    [shouldSendBudget, handleClose, handleSave]
  )

  return (
    <Modal
      title="Dados do orçamento pré-aprovado"
      onBackdropClick={handleClose}
      onClose={handleClose}
      open={isOpen}
      actionsButtons={actionsButtons}
      component="form"
      onSubmit={onSubmit}
    >
      <FormProvider {...methods}>
        <form className={styles.content}>
          <Radio
            className={styles.acceptsWarranty}
            label="Tem garantia?"
            name="acceptsWarranty"
            options={radioOptions}
            onChange={handleRadioChange}
          />
          <Controller
            name="warrantyMonths"
            rules={warrantyMonthsInputRules}
            control={control}
            render={({ value, name }) => (
              <Input
                className={styles.warrantyMonths}
                label="Garantia (meses)"
                name={name}
                value={value}
                style={inputStyle}
                fullWidth
                required={!isWarrantyMonthsDisabled}
                error={Boolean(errors?.warrantyMonths?.message)}
                errorMessage={errors?.warrantyMonths?.message}
                disabled={isWarrantyMonthsDisabled}
                onChange={handleInputChange}
              />
            )}
          />
          <Radio
            className={styles.visitHappened}
            label="Houve visita presencial?"
            name="visitHappened"
            options={radioOptions}
          />
        </form>
      </FormProvider>
    </Modal>
  )
}

EditPreApprovedBudgetModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func,
  isOpen: PropTypes.bool.isRequired,
  shouldSendBudget: PropTypes.bool,
}

EditPreApprovedBudgetModal.defaultProps = {
  handleConfirm: undefined,
  shouldSendBudget: true,
}

export default EditPreApprovedBudgetModal
