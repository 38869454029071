import React, { useCallback, useMemo } from 'react'
import { Button } from '@refera/ui-web'

import useCanDoButtonAction from '_hooks/use-can-do-button-action'
import useRolePermission from '_hooks/use-role-permission'
import useToggle from '_hooks/use-toggle'
import { BUTTONS_ACTION } from '_utils/constants/service-order'
import RefusePreApprovedBudgetConfirmationModal from '_components/budget/modals/refuse-pre-approved-budget-confirmation-modal'
import RefusePreApprovedBudgetModal from '_components/budget/modals/refuse-pre-approved-budget-modal'

const RefuseBudgetButton = () => {
  const { isTradesman } = useRolePermission()
  const [isConfirmationModalOpen, toggleConfirmationModal] = useToggle()
  const [isModalOpen, toggleIsModalOpen] = useToggle()

  const canRefuseInnerBudget = useCanDoButtonAction({
    nameButton: BUTTONS_ACTION.TRADESMAN_REFUSE_INNER_BUDGET,
    isBudgetRelated: true,
  })

  const isRefuseBudgetButtonVisible = useMemo(
    () => isTradesman && canRefuseInnerBudget,
    [canRefuseInnerBudget, isTradesman]
  )

  const handleConfirm = useCallback(() => {
    toggleConfirmationModal()
    toggleIsModalOpen()
  }, [toggleConfirmationModal, toggleIsModalOpen])

  if (!isRefuseBudgetButtonVisible) {
    return null
  }

  return (
    <>
      <Button onClick={toggleConfirmationModal} color="red" variant="secondary">
        Recusar orçamento
      </Button>

      <RefusePreApprovedBudgetConfirmationModal
        handleCancel={toggleConfirmationModal}
        handleConfirm={handleConfirm}
        isOpen={isConfirmationModalOpen}
      />

      <RefusePreApprovedBudgetModal handleClose={toggleIsModalOpen} isOpen={isModalOpen} />
    </>
  )
}

export default RefuseBudgetButton
