import React, { useCallback, useState, useContext, useMemo, useEffect } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useSelector, useDispatch } from 'react-redux'
import { FormControlLabel, Grid, OutlinedInput, Radio, RadioGroup, Typography } from '@mui/material'
import { Input, useConfirm } from '@refera/ui-web'
import { Attachments } from '_components/refera-ui'
import { useParams } from '@reach/router'
import classnames from 'classnames'

import { BudgetContext } from '_utils/budget'
import { formatInputNumber } from '_utils/helpers'
import PreviewComponent from '_components/common/preview-component'
import { getOriginalBudget } from '_modules/budget/selectors'

import { importAttachments, getBudget } from '_/modules/budget/actions'
import { useToast } from '_/hooks/use-toast'
import ConfirmRemoveDialog from '_/components/dialogs/ConfirmRemoveDialog'

import BudgetPaymentDetails from '../budget-payment-details'

import useStyles, { inputStyle } from './styles'
import { ConfidentialDataWarning } from '_/components/ConfidentialDataWarning'
import { currentServiceOrderSelector } from '_modules/service-orders/selectors'

const BudgetForm = () => {
  const styles = useStyles()
  const dispatch = useDispatch()
  const { budgetId } = useParams()

  const { isEditing, setDeletePictures, budgetPictures, setBudgetPictures, budgetTemplate } =
    useContext(BudgetContext)

  const { showToast } = useToast()
  const { isConfirmed } = useConfirm()

  const { control, setValue, watch } = useFormContext()
  const acceptsWarrantyTimeWatch = watch('acceptsWarranty')

  const [previewState, setPreviewState] = useState({
    open: false,
    selectedIndex: undefined,
  })
  const [budgetTemplateItems, setBudgetTemplateItems] = useState()
  const [importType, setImportType] = useState()
  const [dialogMessage, setDialogMessage] = useState('')

  const budget = useSelector(getOriginalBudget(budgetId))?.toJS()
  const serviceOrder = useSelector(currentServiceOrderSelector)

  const categoryAllowsNoAttachments = serviceOrder?.category.toJS()[0].allowsNoAttachments

  const onImportFiles = useCallback(async () => {
    // TODO: Refazer importação de anexos diretamente dentro do componente Attachements para evitar duplicação no futuro.
    // duplicado em src/views/service-order/details/index.jsx
    setDialogMessage(
      'Tem certeza que você deseja importar fotos/vídeos do chamado? Esta ação importa TODOS arquivos anexados pelo cliente.'
    )

    const confirmed = await isConfirmed()
    const hasAttachments = budget?.budgetPictures.length > 0

    try {
      if (confirmed && !hasAttachments) {
        await dispatch(importAttachments(serviceOrder.id, budgetId))
      }

      if (confirmed && hasAttachments) {
        setDialogMessage(
          `Atenção\n\nEste chamado já possui anexos. Você pode estar gerando duplicação de arquivos. Importar mesmo assim?`
        )

        const reconfirmed = await isConfirmed()

        if (reconfirmed) {
          await dispatch(importAttachments(serviceOrder.id, budgetId))
        }
      }

      dispatch(getBudget(serviceOrder.id, budgetId)).then(res =>
        setBudgetPictures(res?.budgetPictures.map(item => ({ id: item?.id, file: item.picture })))
      ) // FORÇA ATUALIZAÇÃO DE TELA

      showToast({
        type: 'success',
        message: 'Ação realizada com sucesso.',
      })
    } catch (e) {
      showToast({
        type: 'error',
        message: e,
      })
    }
  }, [budget, dispatch, budgetId, serviceOrder])

  const handleNumberInputChange = useCallback(event => {
    const { name } = event.target

    setValue(name, formatInputNumber(event, 0, 12))
  }, [])

  const handleDrop = useCallback(files => {
    setBudgetPictures(old => [...old, ...files])
  }, [])

  const handlePictureItemClick = index =>
    setPreviewState({
      open: true,
      selectedIndex: index,
    })

  const handleRemoveAttachments = useCallback(
    (index, pdf) => {
      const newPictureToDelete = budget?.budgetPictures[index]

      setDeletePictures(prevState => [...prevState, newPictureToDelete])

      let elementToDelete = budgetPictures
        .filter(file => file.type !== 'application/pdf')
        .find((_, i) => index === i)

      if (pdf) {
        elementToDelete = budgetPictures
          .filter(file => file.type === 'application/pdf')
          .find((_, i) => index === i)
      }
      const indexOfElements = budgetPictures?.indexOf(elementToDelete)

      if (indexOfElements !== -1) {
        setBudgetPictures(prevState => prevState.filter((_, i) => indexOfElements !== i))
      }
    },
    [budget, budgetPictures]
  )

  useEffect(() => {
    if (Array.isArray(budgetTemplate) ? budgetTemplate.length > 0 : budgetTemplate) {
      setValue('diagnosis', budgetTemplate.diagnosis)
      setValue('proposedSolution', budgetTemplate.proposedSolution)
      setValue('warrantyMonths', budgetTemplate.warrantyMonths)
      if (budgetTemplate.importType === 'importFromBudgetTemplate') {
        setBudgetTemplateItems(budgetTemplate.budgetTemplateServices)
        setImportType(budgetTemplate.importType)
      } else {
        setImportType('importFromServiceOrder')
        setBudgetTemplateItems(budgetTemplate.budgetItems)
      }
    }
  }, [budgetTemplate, importType])

  const minValue = value => {
    if (value === 'Nenhum') {
      return true
    }

    return Number(value) > 1 || "Valor deve ser maior que '1'"
  }

  const attachmentsPdf = useMemo(
    () =>
      budgetPictures?.filter(attachment =>
        attachment instanceof File
          ? attachment.type === 'application/pdf'
          : attachment.file?.indexOf('.pdf') !== -1
      ),
    [budgetPictures]
  )

  return (
    <form className={styles.form}>
      <Grid className={styles.textContainer}>
        <Typography className={styles.textTitle}>
          Diagnóstico do problema
          <span className={styles.required}>*</span>
        </Typography>
        <Controller
          control={control}
          name="diagnosis"
          rules={{ required: true }}
          as={
            <OutlinedInput
              multiline
              disabled={!isEditing}
              className={styles.textInput}
              placeholder="Escreva aqui uma mensagem para o prestador."
              maxRows={6}
              minRows={1}
            />
          }
        />
        <ConfidentialDataWarning style={{ marginTop: 10 }} />
      </Grid>

      <Grid className={styles.textContainer}>
        <Typography className={styles.textTitle}>
          Solução para o problema
          <span className={styles.required}>*</span>
        </Typography>
        <Controller
          control={control}
          name="proposedSolution"
          rules={{ required: true }}
          as={
            <OutlinedInput
              multiline
              disabled={!isEditing}
              placeholder="Descreva aqui a solução para o problema."
              className={styles.textInput}
              maxRows={6}
              minRows={1}
            />
          }
        />
        <ConfidentialDataWarning style={{ marginTop: 10 }} />
      </Grid>

      <BudgetPaymentDetails
        isEditing={isEditing}
        budgetTemplateItems={budgetTemplateItems}
        importType={importType}
      />

      <Grid container wrap="nowrap">
        <Grid item xs={4} className={styles.radioInput}>
          <Typography className={styles.textTitle}>Houve visita presencial?</Typography>
          <Controller
            control={control}
            name="visitHappened"
            as={
              <RadioGroup classes={{ root: styles.radioGroupStyles }} row>
                <FormControlLabel
                  value="false"
                  disabled={!isEditing}
                  control={<Radio />}
                  label="Não"
                  classes={{ root: styles.labelInputRadioButton }}
                />
                <FormControlLabel
                  value="true"
                  disabled={!isEditing}
                  control={<Radio />}
                  label="Sim"
                  classes={{ root: styles.labelInputRadioButton }}
                />
              </RadioGroup>
            }
          />
        </Grid>

        <Grid item xs={4} className={styles.radioInput}>
          <Typography className={styles.textTitle}>Tem garantia?</Typography>
          <Controller
            control={control}
            name="acceptsWarranty"
            as={
              <RadioGroup classes={{ root: styles.radioGroupStyles }} row>
                <FormControlLabel
                  value="false"
                  disabled={!isEditing}
                  control={<Radio />}
                  label="Não"
                  classes={{ root: styles.labelInputRadioButton }}
                />
                <FormControlLabel
                  value="true"
                  disabled={!isEditing}
                  control={<Radio />}
                  label="Sim"
                  classes={{ root: styles.labelInputRadioButton }}
                />
              </RadioGroup>
            }
          />
        </Grid>
        <Grid item xs={4} className={styles.selectInput}>
          <Typography className={styles.textTitle}>Garantia (meses)</Typography>
          {acceptsWarrantyTimeWatch === 'true' ? (
            <Controller
              name="warrantyMonths"
              rules={{ validate: minValue }}
              control={control}
              render={({ value, name }) => (
                <Input
                  className={styles.warrantyMonths}
                  name={name}
                  value={value}
                  style={inputStyle}
                  fullWidth
                  required="true"
                  disabled={!isEditing}
                  onChange={handleNumberInputChange}
                />
              )}
            />
          ) : (
            <Typography variant="h5" className={styles.numberInput}>
              Nenhum
            </Typography>
          )}
        </Grid>
      </Grid>

      <Grid className={styles.imageContainer}>
        <PreviewComponent
          images={budgetPictures?.filter(file => file?.type !== 'application/pdf')}
          {...previewState}
          setPreviewState={setPreviewState}
        />
        <Typography className={styles.textTitle}>
          Adicione fotos ou vídeo do problema
          {!categoryAllowsNoAttachments && <span className={styles.required}>*</span>}
        </Typography>

        <Grid className={classnames(styles.imageWrapper)}>
          <Attachments
            label="Imagens e vídeos"
            readOnly={!isEditing}
            downloadable
            canImportFiles
            files={budgetPictures?.filter(file => file?.type !== 'application/pdf') || []}
            accept={{
              'image/jpeg': [
                '.jpeg',
                '.png',
                '.jpg',
                '.bmp',
                '.webp',
                '.mkv',
                '.mp4',
                '.mov',
                '.avi',
                '.m4v',
                '.mpg',
                '.mpeg',
                '.wmv',
                '.webm',
              ],
            }}
            onDrop={handleDrop}
            onImportFiles={() => onImportFiles()}
            onRemoveItem={index => handleRemoveAttachments(index, false)}
            onItemClick={handlePictureItemClick}
          />
        </Grid>
        <Grid className={classnames(styles.imageWrapper)}>
          <Attachments
            label="Arquivos PDF"
            content="files"
            downloadable
            readOnly={!isEditing}
            files={attachmentsPdf || []}
            accept={{
              'application/pdf': ['.pdf'],
            }}
            onDrop={handleDrop}
            onRemoveItem={index => handleRemoveAttachments(index, true)}
            onItemClick={index => window.open(attachmentsPdf[index].file)}
          />
        </Grid>
      </Grid>
      {dialogMessage && <ConfirmRemoveDialog message={dialogMessage} />}
    </form>
  )
}

export default BudgetForm
