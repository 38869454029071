import React, { useCallback, useContext, useMemo } from 'react'
import { Button } from '@refera/ui-web'
import { useFormContext } from 'react-hook-form'

import useCanDoButtonAction from '_hooks/use-can-do-button-action'
import useRolePermission from '_hooks/use-role-permission'
import { BUTTONS_ACTION } from '_utils/constants/service-order'
import { BudgetContext } from '_utils/budget'

const CancelEditBudgetButton = () => {
  const { isEditing, toggleIsEditing } = useContext(BudgetContext)
  const { isTradesman } = useRolePermission()

  const {
    formState: { defaultValues },
    reset,
  } = useFormContext()

  const tradesmanCanEditBudget = useCanDoButtonAction({
    nameButton: BUTTONS_ACTION.EDIT_BUDGET_BY_PROVIDER,
    isBudgetRelated: true,
  })

  const isCancelButtonVisible = useMemo(() => {
    if (isTradesman) {
      return isEditing && tradesmanCanEditBudget
    }

    return false
  }, [isEditing, isTradesman, tradesmanCanEditBudget])

  const handleCancel = useCallback(() => {
    reset(defaultValues)
    toggleIsEditing()
  }, [toggleIsEditing])

  if (!isCancelButtonVisible) {
    return null
  }

  return (
    <Button onClick={handleCancel} color="red" variant="secondary">
      Cancelar
    </Button>
  )
}

export default CancelEditBudgetButton
