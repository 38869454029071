import React, { useCallback, useContext, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from '@reach/router'
import { Button } from '@refera/ui-web'

import { getOriginalBudget } from '_modules/budget/selectors'
import useCanDoButtonAction from '_hooks/use-can-do-button-action'
import useRolePermission from '_hooks/use-role-permission'
import { BUTTONS_ACTION } from '_utils/constants/service-order'
import { BudgetContext } from '_utils/budget'
import { updateBudget } from '_modules/budget/actions'
import { PERMISSIONS } from '_/utils/constants/permissions'

const EditBudgetButton = () => {
  const { isEditing, toggleIsEditing } = useContext(BudgetContext)
  const { budgetId } = useParams()
  const { isTradesman, checkUserPermission } = useRolePermission()
  const budget = useSelector(getOriginalBudget(budgetId))?.toJS()
  const dispatch = useDispatch()

  const tradesmanCanEditBudget = useCanDoButtonAction({
    nameButton: BUTTONS_ACTION.EDIT_BUDGET_BY_PROVIDER,
    isBudgetRelated: true,
  })

  const isProposeOpened = useMemo(() => budget?.proposeOpened, [budget?.proposeOpened])

  const isEditButtonVisible = useMemo(() => {
    if (isTradesman) {
      return !isEditing && tradesmanCanEditBudget
    }

    return false
  }, [isEditing, isTradesman, tradesmanCanEditBudget])

  const handleEditButtonClick = useCallback(() => {
    if (!isProposeOpened) {
      toggleIsEditing()
      return
    }
    dispatch(
      updateBudget(budget?.serviceOrder, budgetId, {
        released: true,
      })
    )
  }, [isProposeOpened, toggleIsEditing])

  if (!isEditButtonVisible) {
    return null
  }

  return (
    <>
      <Button
        onClick={() =>
          checkUserPermission(
            isTradesman ? PERMISSIONS.ORIGINAL_BUDGETS : PERMISSIONS.BUDGETS,
            'update',
            handleEditButtonClick
          )
        }
        color="primary"
        variant="secondary"
      >
        {isProposeOpened && isTradesman ? 'Aceitar Orçamento' : 'Editar'}
      </Button>
    </>
  )
}

export default EditBudgetButton
