import React, { useCallback, useMemo, useState } from 'react'
import { Menu, MenuItem, Portal, Snackbar } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { Grid } from '@mui/material'
import { navigate, useParams } from '@reach/router'
import Theme from '@refera/ui-core'
import {
  ArrowRight3,
  DollarCircle,
  MessageQuestion,
  Danger,
  Star,
  Edit2,
  Trash,
} from '@refera/ui-icons'
import { useConfirm, Typography } from '@refera/ui-web'
import classnames from 'classnames'
import format from 'date-fns/format'
import isSameDay from 'date-fns/isSameDay'
import ptBR from 'date-fns/locale/pt-BR'
import { FaStar } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import DropdownIcon from '_assets/icons/ic-dropdown.svg'
import Button from '_components/button'
import NewCommentModal from '_components/modal/new-structure-comment-modal'
import IconButton from '_components/svg/icon-button'
import useToggle from '_hooks/use-toggle'
import {
  deleteMessage,
  getAllNotes,
  updateNote,
  deletePaymentAnticipation,
} from '_modules/messages/actions'
import { ROUTES } from '_utils/constants'
import PaymentAdvanceApproval from '_views/finance/payment-advance'
import LogDate from '../log-date'
import useStyles from './styles'
import Svg from '_/components/svg'
import InfoIcon from '_assets/icons/ic-info-circle.svg'
import TickIcon from '_assets/icons/ic-tick-circle.svg'
import DollarCircleIcon from '_assets/icons/ic-dollar-circle.svg'
import CloseCircleIcon from '_assets/icons/ic-close-circle.svg'
import { userSelector } from '_modules/authentication/selectors'
import JustifiedDelayForm from '_views/service-order/justified-delay/justified-delay-form/index'
import DialogModal, { WARNING_MODAL } from '_components/modal/modal-dialog'
import ConfirmRemoveDialog from '_components/dialogs/ConfirmRemoveDialog'
import { formatCurrency, formatDate } from '_/utils/helpers'
import PropTypes from 'prop-types'

// TODO: Decrease component complexity by using it as base to other ones
// e.g. Other components: JustifiedDelayAnnotation, HelpRequestAnnotation, and so on
//      There would be using GeneralAnnotation passing the content as children,
//      and dynamic content (as icon, icon's classNames, handleDelete and handleEditChoice) as prop
const GeneralAnnotation = ({ data, previousDate, noteType, tradesmanList }) => {
  const styles = useStyles()
  const dispatch = useDispatch()
  const { serviceOrderId } = useParams()
  const user = useSelector(userSelector)

  const [openTooltip, setOpenTooltip] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const [paymentAdvanceModalOpen, togglePaymentAdvanceModalOpen] = useToggle()
  const [paymentAdvanceItem, setPaymentAdvanceItem] = useState(null)
  const [successSnackbar, toggleSuccessSnackbar] = useToggle()
  const [openJustifiedDelayForm, toggleJustifiedDelayForm] = useToggle()
  const [helpObj, setHelpObj] = useState(null)
  const [newDataAtt, setNewDataAtt] = useState(null)
  const [newCommentModal, setNewCommentModal] = useState({ isOpen: false, title: '' })
  const [toggleHelpView] = useToggle()
  const simpleDateFormat = 'dd/MM/yyy'

  const name = data ? data?.senderName?.split(' ') : ''
  const initials =
    name && name[0][0] + name[name.length - 1][0]
      ? (name[0][0] + name[name.length - 1][0])?.toUpperCase()
      : ''

  const [modalDialog, setModalDialog] = useState({ isOpen: false, subTitle: '' })
  const [permissionModal, setPermissionModal] = useState({ isOpen: false, subTitle: '' })
  const [dialogMessage, setDialogMessage] = useState('')

  const { isConfirmed } = useConfirm()

  const openDropdown = Boolean(anchorEl)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
    setOpenTooltip(false)
  }

  const handleToggleIsImportant = useCallback(() => {
    dispatch(updateNote(data.id, { type: data.noteType, isImportant: !data.isImportant }))
  }, [data])

  const handleClose = () => {
    setAnchorEl(null)
  }

  const onCommentModal = useCallback(() => {
    setNewCommentModal({
      isOpen: true,
      title: 'Comentários',
      comment: data,
    })
  }, [data])

  const onDeleteMessage = useCallback(
    async removeMessage => {
      setDialogMessage(removeMessage)
      const confirmed = await isConfirmed()
      if (confirmed) {
        if (data.noteType === 'payment_anticipation') {
          dispatch(deletePaymentAnticipation(data.id)).then(() =>
            dispatch(getAllNotes({ serviceOrderId }))
          )
        } else {
          dispatch(deleteMessage(data.id)).then(() => dispatch(getAllNotes({ serviceOrderId })))
        }
      }
    },
    [dialogMessage, data]
  )

  const handleDelete = useCallback(() => {
    switch (data.noteType) {
      case 'comment':
        onDeleteMessage('Você tem certeza que deseja excluir este registro?')
        break
      case 'help_request':
        handleModalsChoice('redirect')
        break
      case 'justified_delay':
        handleModalsChoice('redirect')
        break
      default:
        break
    }
  }, [data])

  const handleModalsChoice = type => {
    if (type === 'permission') {
      setPermissionModal({
        isOpen: true,
        subTitle: 'Você não tem permissão para esta ação.',
        type: WARNING_MODAL,
      })
    } else if (type === 'redirect') {
      setModalDialog({
        isOpen: true,
        subTitle: 'Para realizar essa ação você será direcionado para a página específica.',
        type: WARNING_MODAL,
        isClose: handleGetHelpRequest,
      })
    }
  }

  const handleEditChoice = useCallback(() => {
    switch (noteType) {
      case 'COMMENT':
        onCommentModal()
        break
      case 'HELP_REQUEST':
        handleModalsChoice('redirect')
        break
      case 'JUSTIFIED_DELAY':
        if (user.getRoles.includes('cs_refera')) {
          handleModalsChoice('redirect')
        } else {
          handleModalsChoice('permission')
        }
        break
      case 'PAYMENT_ANTICIPATION':
        user.getRoles.includes('cs_refera')
          ? handleGetAdvancedOrder()
          : handleModalsChoice('permission')
        break
      default:
        return null
    }
    return null
  }, [noteType, data])

  const handleGetAdvancedOrder = useCallback(() => {
    setPaymentAdvanceItem(data)

    togglePaymentAdvanceModalOpen()
  }, [data, togglePaymentAdvanceModalOpen])

  const renderMenuItems = useMemo(() => {
    const items = [
      {
        icon: (
          <IconButton disabled>
            <Edit2 className={styles.editIcon} />
          </IconButton>
        ),
        label: <Typography className={styles.edit}>Editar</Typography>,
        params: {
          onClick: handleEditChoice,
        },
        show: true,
      },
      {
        icon: (
          <IconButton disabled>
            <Trash className={styles.trashIcon} />
          </IconButton>
        ),
        label: <Typography className={styles.delete}>Excluir</Typography>,
        params: {
          onClick: handleDelete,
        },
        show: !noteType.includes('PAYMENT_ANTICIPATION'),
      },
    ]
    return items.map(
      item =>
        item.show && (
          <MenuItem {...item.params}>
            {item.icon} {item.label}
          </MenuItem>
        )
    )
  }, [data])

  const handleGetHelpRequest = useCallback(() => {
    navigate(`${ROUTES.HELP_REQUESTS}/tramites/${data.id}`)
  }, [data.id])

  const handleClearMessage = () => {
    if (helpObj?.answer?.length > 0 && helpObj?.resolved) {
      toggleHelpView()
    } else {
      toggleJustifiedDelayForm()
    }
    setHelpObj(undefined)
  }

  const handleSuccessSnackbar = useCallback(() => {
    toggleSuccessSnackbar()
  }, [toggleSuccessSnackbar])

  const isLogDateVisible = useMemo(() => {
    const newDate = new Date(data.createdAt)
    const oldDate = new Date(previousDate)

    if (previousDate === 0) return true
    return !isSameDay(newDate, oldDate)
  }, [data.createdAt, previousDate])

  const handleCloseModal = useCallback(() => {
    togglePaymentAdvanceModalOpen()
  }, [togglePaymentAdvanceModalOpen])

  const renderFormatedDate = (date, dateFormat) => {
    const result = format(new Date(date), dateFormat, {
      locale: ptBR,
    })

    return result
  }

  const renderPaymentStatus = useMemo(() => {
    if (data.status === 'pending') {
      return (
        <Grid className={styles.statusTag}>
          <Svg icon={InfoIcon} className={styles.helpRequestIcon} />
          <Typography>Pendente</Typography>
        </Grid>
      )
    }
    if (data.status === 'approved' && data.activeBatchId) {
      return (
        <Grid className={styles.statusTag}>
          <Svg icon={TickIcon} className={styles.helpRequestIcon} />
          <Typography>Em pagamento</Typography>
        </Grid>
      )
    }
    if (data.status === 'approved' || data.status === 'paid') {
      const isPaid = data.datetimePayed || data.status === 'paid'
      return (
        <Grid className={styles.statusTag}>
          <Svg icon={isPaid ? DollarCircleIcon : TickIcon} className={styles.helpRequestIcon} />
          <Typography>{isPaid ? 'Pago' : 'Aprovado'}</Typography>
        </Grid>
      )
    }
    if (data.status === 'reproved') {
      return (
        <Grid className={styles.statusTag}>
          <Svg icon={CloseCircleIcon} className={styles.helpRequestIcon} />
          <Typography>Reprovado</Typography>
        </Grid>
      )
    }

    return null
  })

  return (
    <>
      <Grid>{isLogDateVisible && <LogDate data={data?.createdAt} />}</Grid>
      <Grid className={styles.container}>
        {noteType === 'JUSTIFIED_DELAY' && (
          <Grid className={classnames(styles.icon, styles.justifiedDelay)}>
            <IconButton disabled className={styles.commentIcon}>
              <Danger color="black" />
            </IconButton>
          </Grid>
        )}

        {noteType === 'HELP_REQUEST' && (
          <Grid className={classnames(styles.icon, styles.helpRequest)}>
            <IconButton disabled className={styles.commentIcon}>
              <MessageQuestion color="white" />
            </IconButton>
          </Grid>
        )}

        {noteType === 'PAYMENT_ANTICIPATION' && (
          <Grid className={classnames(styles.icon, styles.paymentAnticipation)}>
            <IconButton disabled className={styles.commentIcon}>
              <DollarCircle color="black" />
            </IconButton>
          </Grid>
        )}

        {noteType === 'COMMENT' && (
          <Grid className={styles.icon}>
            <Button disabled className={styles.commentIcon}>
              <Typography className={styles.title}>{initials}</Typography>
            </Button>
          </Grid>
        )}

        <Grid className={styles.wrapper}>
          <Grid className={styles.raw}>
            <Typography className={styles.title}>{data.senderName}</Typography>
            <Typography className={styles.date}>{formatDate(data?.createdAt)}</Typography>
            <Grid className={styles.buttons}>
              {data.isImportant ? (
                <FaStar
                  size={18}
                  className={styles.styleEnabled}
                  onClick={handleToggleIsImportant}
                />
              ) : (
                <Star
                  size={18}
                  className={styles.styleDisabled}
                  onClick={handleToggleIsImportant}
                />
              )}
              <IconButton
                icon={DropdownIcon}
                aria-controls={openTooltip ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={openTooltip ? 'true' : undefined}
                onClick={handleClick}
              />
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={openDropdown}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
                PaperProps={{
                  style: {
                    marginTop: 40,
                    marginLeft: 8,
                  },
                }}
              >
                {renderMenuItems}
              </Menu>
            </Grid>
          </Grid>
          {noteType === 'JUSTIFIED_DELAY' && (
            <Grid className={styles.wrapper}>
              <Typography className={styles.description}>
                <span className={styles.span}>Solicitante:</span>
                <span className={styles.spanMesage}>{data.requester}</span>
              </Typography>
              <Typography className={styles.description}>
                <span className={styles.span}>Tipo de Justificativa:</span>
                <span className={styles.spanMesage}> {data.justifieType}</span>
              </Typography>
              <Typography className={styles.description}>
                <span className={styles.span}>Motivo: </span>
                <span className={styles.spanMesage}>{data.reason}</span>
              </Typography>
              <Typography className={styles.description}>
                <span className={styles.span}>Resolverei até: </span>
                <span className={styles.spanMesage}> {formatDate(data?.expectedDeadline)}</span>
              </Typography>
              <Typography className={styles.description}>
                <span className={styles.spanMesage}>{data.message}</span>
              </Typography>
              <Button
                variant="ghost"
                className={styles.link}
                onClick={handleGetHelpRequest}
                endIcon={<ArrowRight3 color={Theme.Colors.Primary.Base} />}
              >
                Ver mais detalhes
              </Button>
              <span disabled className={styles.tag}>
                Atraso Justificado
              </span>
              {openJustifiedDelayForm && (
                <JustifiedDelayForm
                  closeModal={handleClearMessage}
                  message={newDataAtt ?? helpObj}
                  setMessage={setNewDataAtt}
                  tradesmanInfo={tradesmanList}
                />
              )}
            </Grid>
          )}
          {noteType === 'HELP_REQUEST' && (
            <Grid className={styles.wrapper}>
              {data.resolved === false && (
                <Grid className={styles.statusTag}>
                  <Svg icon={InfoIcon} className={styles.helpRequestIcon} />
                  <Typography>Pendente</Typography>
                </Grid>
              )}
              {data.resolved === true && (
                <Grid className={styles.statusTag}>
                  <Svg icon={TickIcon} className={styles.helpRequestIcon} />
                  <Typography>Resolvido</Typography>
                </Grid>
              )}
              <Typography className={styles.description}>
                <span className={styles.span}>Assunto: </span>
                <span className={styles.spanMesage}>{data.subject}</span>
              </Typography>
              <Typography className={styles.description}>
                <span className={styles.spanMesage}>{data.message}</span>
              </Typography>
              <Button
                variant="ghost"
                className={styles.link}
                onClick={handleGetHelpRequest}
                endIcon={<ArrowRight3 color={Theme.Colors.Primary.Base} />}
              >
                Ver mais detalhes
              </Button>
              <span disabled className={styles.tag}>
                Item de ajuda
              </span>
            </Grid>
          )}
          {noteType === 'PAYMENT_ANTICIPATION' && (
            <Grid className={styles.wrapper}>
              {renderPaymentStatus}
              <Typography className={styles.description}>
                <span className={styles.span}>Data do pedido de antecipação: </span>
                <span className={styles.spanMesage}>
                  {renderFormatedDate(data.createdAt, simpleDateFormat)}
                </span>
              </Typography>
              <Typography className={styles.description}>
                <span className={styles.span}>Solicitante: </span>
                <span className={styles.spanMesage}> {data.senderName} </span>
              </Typography>
              <Typography className={styles.description}>
                <span className={styles.span}>Valor: </span>
                <span className={styles.spanMesage}>{formatCurrency(data.value)} </span>
              </Typography>
              <Button
                variant="ghost"
                className={styles.link}
                endIcon={<ArrowRight3 color={Theme.Colors.Primary.Base} />}
                onClick={handleGetAdvancedOrder}
              >
                Ver mais detalhes
              </Button>
              <span disabled className={styles.tag}>
                Antecipação
              </span>
              {paymentAdvanceModalOpen && (
                <PaymentAdvanceApproval
                  paymentAdvanceItem={paymentAdvanceItem}
                  setSuccessSnackbar={handleSuccessSnackbar}
                  closeModal={handleCloseModal}
                />
              )}
              <Portal>
                <Snackbar
                  open={successSnackbar}
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                  autoHideDuration={6000}
                  onClose={toggleSuccessSnackbar}
                >
                  <Alert
                    onClose={toggleSuccessSnackbar}
                    severity="success"
                    variant="filled"
                    sx={{ width: '100%' }}
                  >
                    <Typography>Sua ação foi realizada com sucesso</Typography>
                  </Alert>
                </Snackbar>
              </Portal>
            </Grid>
          )}
          {noteType === 'COMMENT' && (
            <Grid>
              <Grid className={styles.wrapper}>
                <Grid className={styles.wrapper}>
                  <Typography className={styles.description}>
                    <span className={styles.spanMesage}> {data.message} </span>
                  </Typography>
                  <Button
                    variant="ghost"
                    className={styles.link}
                    onClick={onCommentModal}
                    endIcon={<ArrowRight3 color={Theme.Colors.Primary.Base} />}
                  >
                    Ver mais detalhes
                  </Button>
                  <span disabled className={styles.tag}>
                    {data.visibleTo}
                  </span>
                </Grid>
              </Grid>
              <NewCommentModal
                newCommentModal={newCommentModal}
                setNewCommentModal={setNewCommentModal}
              />
            </Grid>
          )}
          {modalDialog && <DialogModal modalDialog={modalDialog} setModalDialog={setModalDialog} />}
          {permissionModal && (
            <DialogModal modalDialog={permissionModal} setModalDialog={setPermissionModal} />
          )}
          {dialogMessage && <ConfirmRemoveDialog message={dialogMessage} />}
        </Grid>
      </Grid>
    </>
  )
}

GeneralAnnotation.propTypes = {
  data: PropTypes.shape({
    expectedDeadline: PropTypes.instanceOf(Date),
  }),
}

GeneralAnnotation.defaultProps = {
  data: {
    expectedDeadline: new Date(),
  },
}

export default React.memo(GeneralAnnotation)
