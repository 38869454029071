import React, { useCallback, useEffect, useMemo } from 'react'
import { Typography, Grid, TextField } from '@material-ui/core'
import PropTypes from 'prop-types'
import { Controller, useFieldArray, useFormContext } from 'react-hook-form'
import classnames from 'classnames'

import Button from '_components/button'
import Svg from '_components/svg'
import CloseIcon from '_assets/icons/ic-close-bordered-red.svg'
import IconButton from '_components/svg/icon-button'
import CurrencyTextField from '_components/textfield/currency'
import { convertCurrencyValueToNumber, formatCurrency } from '_utils/helpers'

import useStyles from './styles'

const BudgetPaymentDetails = ({ isEditing, budgetTemplateItems, importType }) => {
  const styles = useStyles({ isEditing })

  const { control, watch, setValue } = useFormContext()
  const budgetItemsWatch = watch('budgetItems')
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'budgetItems',
  })

  const totalMaterialPrice = useMemo(
    () => budgetItemsWatch?.reduce((acc, item) => acc + item.price, 0) ?? 0,
    [budgetItemsWatch]
  )

  const handleAppendItem = useCallback(() => {
    append({ description: '', price: 0 })
  }, [])

  const handleRemoveItem = useCallback(event => {
    const { index } = event.currentTarget.dataset
    remove(index)
  }, [])

  useEffect(() => {
    if (isEditing && budgetTemplateItems) {
      fields.forEach((_, index) => remove(index))

      const itemsToAdd =
        importType === 'importFromBudgetTemplate'
          ? budgetTemplateItems?.map(item => ({
              description: item?.get('description'),
              price: item?.get('price'),
            }))
          : budgetTemplateItems?.map(item => ({
              description: item?.description,
              price: item?.price,
            }))

      itemsToAdd.forEach(item => append(item))
    }
  }, [JSON.stringify(budgetTemplateItems), importType])

  return (
    <Grid className={styles.container}>
      <Typography className={styles.title}>Orçamento detalhado</Typography>
      <Grid className={styles.contentLabelContainer}>
        <Typography className={styles.field}>Descrição</Typography>
        <Typography>R$</Typography>
      </Grid>

      {fields?.map((item, index) => (
        <li className={styles.listItem} key={item.id}>
          <Grid className={styles.fieldsContainer}>
            <Controller
              name={`budgetItems[${index}].description`}
              placeholder={`Material ${index + 1}`}
              control={control}
              defaultValue={item.description}
              as={
                <TextField
                  disabled={!isEditing}
                  variant="outlined"
                  size="small"
                  className={styles.field}
                />
              }
            />
            <Grid className={styles.secondFields}>
              <Controller
                name={`budgetItems[${index}].price`}
                defaultValue={fields[`${index}`]?.price || 0}
                control={control}
                rules={{
                  setValueAs: value =>
                    convertCurrencyValueToNumber(value ?? fields[`${index}`]?.price),
                }}
                as={
                  <CurrencyTextField
                    disabled={!isEditing}
                    name={`budgetItems[${index}].price`}
                    setValue={setValue}
                  />
                }
              />
              {isEditing && (
                <IconButton
                  onClick={handleRemoveItem}
                  data-index={index}
                  className={styles.iconCloseButton}
                >
                  <Svg icon={CloseIcon} className={styles.closeIcon} />
                </IconButton>
              )}
            </Grid>
          </Grid>
        </li>
      ))}

      <Grid className={styles.totalContainer}>
        <Grid className={styles.totalMaterialContainer}>
          <Button
            className={classnames(styles.button, {
              [styles.disabled]: !isEditing,
            })}
            variant="outlined"
            color="primary"
            onClick={handleAppendItem}
          >
            +Adicionar item
          </Button>
          <Typography className={styles.title}>Total de material</Typography>
        </Grid>
        <Typography className={styles.title}>{formatCurrency(totalMaterialPrice)}</Typography>
      </Grid>
      <Grid className={styles.totalPriceContainer}>
        <Typography>Total</Typography>
        <Typography className={classnames(styles.finalPrice, styles.title)}>
          {formatCurrency(totalMaterialPrice)}
        </Typography>
      </Grid>
    </Grid>
  )
}

BudgetPaymentDetails.propTypes = {
  isEditing: PropTypes.bool.isRequired,
}

export default BudgetPaymentDetails
