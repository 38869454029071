import { makeStyles } from '@material-ui/styles'
import Theme from '@refera/ui-core'

export default makeStyles(({ spacing }) => ({
  form: {
    padding: spacing(32),
  },
  required: {
    marginLeft: '5px',
    color: 'red',
    fontSize: '16px',
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginBottom: '40px',
  },
  textTitle: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '16px',
    fontWeight: '500',
    fontSize: '16px',
    color: 'black',
  },
  textInput: {
    display: 'block',
    width: '100%',
    height: '132px',
    fontSize: 16,
  },
  containerSelects: {
    marginTop: '24px',
    marginBottom: '32px',
  },
  labelInputRadioButton: {
    marginLeft: '0px',
    marginRight: '0px',
    gap: '10px',
  },
  radioGroupStyles: {
    gap: '56px',
  },
  numberInput: {
    width: '336px',
    height: '40px',
    fontSize: 16,
  },
  imageContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginBottom: '40px',
  },
  imageWrapper: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  disableClick: {
    pointerEvents: 'none',
  },
}))

export const inputStyle = {
  fontSize: `${Theme.Typography.FontSize.XXSmall}px`,
}
