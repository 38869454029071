/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { Typography, Grid } from '@material-ui/core'

import Trash from '_assets/icons/ic-trash.svg'
import IconButton from '_components/svg/icon-button'

import useStyles from './styles'

// eslint-disable-next-line react/prop-types, no-unused-vars
const AccordionInfo = ({ type, isFirst, values, isEditing, handleDeleteValue, userEdit }) => {
  const styles = useStyles()
  const handleReturnCaseSubTitle = useCallback(() => {
    switch (type) {
      case 'csrefera_users':
        return (
          <Grid className={[styles.titleContainer, styles.regionDetails]}>
            <Typography className={styles.title} component="p" variant="body1">
              Nome completo
            </Typography>
            <Typography className={styles.title} component="p" variant="body1">
              Email
            </Typography>
          </Grid>
        )
      case 'region':
        return (
          <Grid className={[styles.titleContainer, styles.regionDetails]}>
            <Typography className={styles.title} component="p" variant="body1">
              Estado
            </Typography>
            <Typography className={styles.title} component="p" variant="body1">
              Cidade
            </Typography>
          </Grid>
        )
      case 'agency':
        return (
          <Grid className={[styles.titleContainer, styles.regionDetails]}>
            <Typography className={styles.title} component="p" variant="body1">
              Nome da Imobiliária
            </Typography>
          </Grid>
        )
      case 'category':
        return (
          <Grid className={[styles.titleContainer, styles.regionDetails]}>
            <Typography className={styles.title} component="p" variant="body1">
              Categoria
            </Typography>
          </Grid>
        )
      case 'service':
        return (
          <Grid className={[styles.titleContainer, styles.regionDetails]}>
            <Typography className={styles.title} component="p" variant="body1">
              Serviço
            </Typography>
          </Grid>
        )
      default:
        return null
    }
  }, [])

  const handleReturnCaseValues = useCallback(() => {
    switch (type) {
      case 'csrefera_users':
        return (
          <Grid className={[styles.regionDetails, styles.value]}>
            <Typography component="p" variant="body1">
              {values?.name}
            </Typography>
            <Typography component="p" variant="body1">
              {values?.email}
            </Typography>
          </Grid>
        )
      case 'region':
        return (
          <Grid className={[styles.regionDetails, styles.value]}>
            <Typography component="p" variant="body1">
              {values?.state}
            </Typography>
            <Typography component="p" variant="body1">
              {values?.city}
            </Typography>
          </Grid>
        )
      case 'agency':
      case 'category':
        return (
          <Typography className={styles.value} component="p" variant="body1">
            {values?.name}
          </Typography>
        )
      case 'service':
        return (
          <Typography className={styles.value} component="p" variant="body1">
            {values?.name}
          </Typography>
        )
      default:
        return null
    }
  }, [type, values])

  return (
    <Grid
      className={styles.wrapper}
      onClick={userEdit}
      style={{ cursor: isEditing ? 'pointer' : 'default' }}
    >
      {handleReturnCaseSubTitle}
      <Grid className={styles.details}>
        {handleReturnCaseValues()}
        {isEditing && (
          <Grid className={styles.buttonsContainer}>
            <IconButton icon={Trash} onClick={() => handleDeleteValue(values)} />
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}

AccordionInfo.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  values: PropTypes.shape({ role: PropTypes.object }).isRequired,
  handleDeleteValue: PropTypes.func.isRequired,
}

export default React.memo(AccordionInfo)
