import React, { useCallback, useMemo, useContext } from 'react'
import { Button } from '@refera/ui-web'

import useCanDoButtonAction from '_hooks/use-can-do-button-action'
import useRolePermission from '_hooks/use-role-permission'
import { BUTTONS_ACTION } from '_utils/constants/service-order'
import { BudgetContext, UPDATE_BUDGET_TYPE } from '_utils/budget'
import useToggle from '_/hooks/use-toggle'
import SimpleConfirmModal from '_/components/modal/simple-confirm-modal'

const TemporarySaveBudgetButton = () => {
  const { isAdmin, isTradesman } = useRolePermission()
  const [isConfirmationModalOpen, toggleIsConfirmationModalOpen] = useToggle()

  const {
    handleUpdateBudgetItems,
    isEditing,
    setUpdateBudgetType,
    isUpdateBudgetLoading,
    handleUpdateBudget,
  } = useContext(BudgetContext)

  const canTemporarySave = useCanDoButtonAction({
    nameButton: BUTTONS_ACTION.TEMPORARY_SAVE,
    isBudgetRelated: true,
  })

  const label = useMemo(() => (isAdmin ? 'Salvar' : 'Salvar temporário'), [isAdmin])

  const isTemporaryButtonVisible = useMemo(
    () => ((canTemporarySave && isTradesman) || isAdmin) && isEditing,
    [canTemporarySave, isAdmin, isTradesman, isEditing]
  )

  const handleTemporarySave = useCallback(() => {
    setUpdateBudgetType(UPDATE_BUDGET_TYPE.TEMPORARY)
    handleUpdateBudgetItems()
  }, [handleUpdateBudgetItems, setUpdateBudgetType])

  const handleSave = useCallback(() => {
    toggleIsConfirmationModalOpen()
    handleUpdateBudget()
  }, [handleUpdateBudget])

  if (!isTemporaryButtonVisible) {
    return null
  }

  return (
    <>
      <Button
        onClick={isAdmin ? toggleIsConfirmationModalOpen : handleTemporarySave}
        color="primary"
        variant="secondary"
        disabled={isUpdateBudgetLoading}
      >
        {label}
      </Button>

      {isConfirmationModalOpen && (
        <SimpleConfirmModal
          title="Você tem certeza que deseja executar esta ação?"
          handleSubmit={handleSave}
          handleModal={toggleIsConfirmationModalOpen}
        />
      )}
    </>
  )
}

export default TemporarySaveBudgetButton
