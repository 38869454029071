/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import React, { useMemo, useCallback, useState, useEffect } from 'react'
import { Grid, Select, FormControl, Typography, MenuItem } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import {
  updateServiceOrderById,
  updateServiceOrderClassifications,
} from '_modules/service-orders/actions'
import { getClassification } from '_modules/classification/actions'

import * as Modal from '_components/modal/generic-modal'
import useStyles from './styles'
import Button from '_/components/button'
import { classificationsServiceOrderSelector } from '_/modules/classification/selectors'

export function AddClassificationModal({
  openClassificationModal,
  setOpenClassificationModal,
  agencyId,
  serviceOrderId,
  title,
  numberEditClassification,
  getClassificationsToList,
}) {
  const dispatch = useDispatch()
  const styles = useStyles()

  const classificationsByServiceOrder = useSelector(classificationsServiceOrderSelector)

  const [classification, setClassification] = useState([])
  const [selectItem, setSelectItem] = useState()
  const [openDeleteModalConfirmation, setOpenDeleteModalConfirmation] = useState()

  const getClassificationsToListByAgency = useCallback(() => {
    dispatch(
      getClassification({
        pageSize: 1000,
        agency: agencyId,
        classificationType: 'None',
      })
    ).then(res => setClassification(res.results))
  }, [agencyId])

  useEffect(() => {
    getClassificationsToListByAgency()
  }, [agencyId])

  const menuProps = useMemo(
    () => ({
      classes: { paper: styles.subjectFielAddModal },
      getContentAnchorEl: null,
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'center',
      },
      transformOrigin: {
        vertical: 'top',
        horizontal: 'center',
      },
    }),
    []
  )

  const handleSelectClassification = useCallback(() => {
    if (numberEditClassification) {
      return dispatch(
        updateServiceOrderClassifications(serviceOrderId, {
          oldClassification: numberEditClassification,
          newClassification: selectItem,
        })
      ).then(() => {
        setOpenClassificationModal(false)
        getClassificationsToList()
      })
    }
    return dispatch(updateServiceOrderById(serviceOrderId, { classifications: [selectItem] })).then(
      () => {
        setOpenClassificationModal(false)
        getClassificationsToList()
      }
    )
  }, [dispatch, selectItem, numberEditClassification])

  const handleRemoveClassification = useCallback(() => {
    dispatch(
      updateServiceOrderClassifications(serviceOrderId, {
        oldClassification: numberEditClassification,
      })
    ).then(() => {
      setOpenDeleteModalConfirmation(false)
      getClassificationsToList()
    })
  }, [dispatch, numberEditClassification])

  function handleOpenRemoveClassificationModal() {
    setOpenClassificationModal(false)
    setOpenDeleteModalConfirmation(true)
  }

  return (
    <>
      <Modal.Root
        className={styles.root}
        open={openClassificationModal}
        onClose={() => setOpenClassificationModal(false)}
      >
        <Modal.TitleModal title={title} />

        <Modal.Content className={styles.dialogContent}>
          <FormControl className={styles.form}>
            <Grid className={styles.inlineLabelAddModal}>
              <Typography className={styles.labelsAddModal}>Classificação do chamado</Typography>
            </Grid>
            <Select
              labelId="classification"
              MenuProps={menuProps}
              className={styles.categorySelectAddModal}
              value={selectItem}
              onChange={e => setSelectItem(e.target.value)}
              displayEmpty
              required
            >
              {classification?.map(item => {
                if (
                  numberEditClassification === item?.id ||
                  (!classificationsByServiceOrder.find(
                    itemAlreadyInServiceOrder => itemAlreadyInServiceOrder?.id === item?.id
                  ) &&
                    item.active)
                ) {
                  return (
                    <MenuItem value={item?.id} key={item?.id}>
                      {item.classificationName}
                    </MenuItem>
                  )
                }
                return false
              })}
            </Select>
          </FormControl>
        </Modal.Content>

        <Modal.Actions className={Boolean(numberEditClassification) && styles.actionEdit}>
          {Boolean(numberEditClassification) && (
            // eslint-disable-next-line react/jsx-no-bind
            <Button className={styles.link} onClick={handleOpenRemoveClassificationModal}>
              Excluir
            </Button>
          )}
          <Modal.Actions>
            <Modal.ButtonRed onClick={() => setOpenClassificationModal(false)}>
              Cancelar
            </Modal.ButtonRed>
            <Modal.ButtonFullBlue onClick={handleSelectClassification}>Salvar</Modal.ButtonFullBlue>
          </Modal.Actions>
        </Modal.Actions>
      </Modal.Root>

      <Modal.Root
        className={styles.rootConfirm}
        open={openDeleteModalConfirmation}
        onClose={() => setOpenDeleteModalConfirmation(false)}
      >
        <Modal.TitleModal title="Você tem certeza que deseja excluir este registro?" />

        <Modal.Actions className={styles.actionConfirm}>
          <Modal.ButtonFullRed onClick={() => setOpenDeleteModalConfirmation(false)}>
            Não
          </Modal.ButtonFullRed>
          <Modal.ButtonFullBlue onClick={handleRemoveClassification}>Sim</Modal.ButtonFullBlue>
        </Modal.Actions>
      </Modal.Root>
    </>
  )
}
