import React, { useContext, useMemo } from 'react'
import { useParams } from '@reach/router'
import { Button } from '@refera/ui-web'

import { getOriginalBudget } from '_modules/budget/selectors'
import useCanDoButtonAction from '_hooks/use-can-do-button-action'
import useRolePermission from '_hooks/use-role-permission'
import { BUTTONS_ACTION } from '_utils/constants/service-order'
import { BudgetContext } from '_utils/budget'
import useBoolean from '_hooks/use-toggle'
import BudgetTemplateForm from '_components/budget/navbar/save-model-budget-button/budget-template-form'
import { useSelector } from 'react-redux'

const SaveModelBudgetButton = () => {
  const { isEditing } = useContext(BudgetContext)
  const { budgetId } = useParams()
  const { isTradesman } = useRolePermission()
  const budget = useSelector(getOriginalBudget(budgetId))?.toJS()
  const [isSaveModelOpen, toggleSaveModel] = useBoolean()

  const tradesmanCanEditBudget = useCanDoButtonAction({
    nameButton: BUTTONS_ACTION.EDIT_BUDGET_BY_PROVIDER,
    isBudgetRelated: true,
  })

  const isEditButtonVisible = useMemo(() => {
    if (isTradesman) {
      return !isEditing && tradesmanCanEditBudget
    }

    return false
  }, [isEditing, isTradesman, tradesmanCanEditBudget])

  if (!isEditButtonVisible) {
    return null
  }

  return (
    <>
      <Button onClick={toggleSaveModel} color="primary" variant="secondary">
        Salvar Modelo
      </Button>

      {isSaveModelOpen && <BudgetTemplateForm closeModal={toggleSaveModel} budget={budget} />}
    </>
  )
}

export default SaveModelBudgetButton
