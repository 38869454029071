import React, { useCallback, useMemo } from 'react'

import { Button } from '@refera/ui-web'
import { navigate } from '@reach/router'
import { useDispatch, useSelector } from 'react-redux'

import { currentServiceOrderSelector } from '_modules/service-orders/selectors'
import { ROUTES } from '_/utils/constants'
import useFetchCall from '_/hooks/use-fetch-call'
import {
  GET_VERIFY_TRADESMAN_INSTALLMENTS,
  getVerifyTradesmanInstallments,
} from '_/modules/finance/actions'
import { useToast } from '_/hooks/use-toast'
import { formatErrorMessage } from '_/views/finance/utils/FormatErrorMessage'
import { menuItemsSelector } from '_/modules/menu-items/selector'

const ViewInstallmentsButton = () => {
  const dispatch = useDispatch()
  const { showToast } = useToast()

  const serviceOrder = useSelector(currentServiceOrderSelector)
  const serviceOrderId = useMemo(() => serviceOrder?.get('id'), [serviceOrder?.get('id')])

  const userMenuItems = useSelector(menuItemsSelector)

  // Based on tradesman menu item 'receipts' if it's active
  const showInstallmentsButton = useMemo(
    () => userMenuItems?.find(item => item.path === ROUTES.INSTALLMENTS_RECEIPTS && item.active),
    [userMenuItems]
  )

  const fetchVerifyTradesmanInstallments = useCallback(() => {
    dispatch(getVerifyTradesmanInstallments(serviceOrderId))
      .then(() => navigate(`${ROUTES.INSTALLMENTS_RECEIPTS}/?chamado=${serviceOrderId}`))
      .catch(error =>
        showToast({
          type: 'error',
          message: formatErrorMessage(error),
        })
      )
  }, [dispatch, serviceOrderId])

  const [isVerifyingInstallments] = useFetchCall(GET_VERIFY_TRADESMAN_INSTALLMENTS.ACTION)

  return showInstallmentsButton ? (
    <Button
      variant="secondary"
      onClick={fetchVerifyTradesmanInstallments}
      disabled={isVerifyingInstallments}
    >
      Ver parcelas
    </Button>
  ) : (
    <></>
  )
}

export default ViewInstallmentsButton
