import React, { useCallback, useContext } from 'react'
import { useForm } from 'react-hook-form'
import {
  Grid,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Paper,
  Slide,
  Typography,
} from '@material-ui/core'
import Draggable from 'react-draggable'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import Button from '_components/button'
import useStyles from './styles'
import CustomRadio from '_components/budget/modals/edit-pre-approved-budget-modal/radio'
import Select from '_components/select'
import { BudgetContext } from '_utils/budget'
import { getActiveBudget } from '_modules/service-orders/actions'
import { useToast } from '_/hooks/use-toast'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const PaperComponent = props => {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

const ImportBudgetTemplateForm = ({ closeModal, budgetTemplates, serviceOrdersId }) => {
  const styles = useStyles()
  const dispatch = useDispatch()
  const { setBudgetTemplate } = useContext(BudgetContext)
  const { setValue, control, register } = useForm()
  const { showToast } = useToast()

  const handleSaveModel = useCallback(formData => {
    const importType = formData.target.importType.value

    if (importType === 'importFromBudgetTemplate') {
      const budgetTemplateId = formData.target.template.value
      if (!budgetTemplateId) {
        showToast({
          type: 'error',
          message: 'Campo modelo requerido!',
        })
      } else {
        const budgetTemplateObject = budgetTemplates.filter(temp => {
          return Number(budgetTemplateId) === temp.id
        })
        budgetTemplateObject[0].importType = importType
        setBudgetTemplate(budgetTemplateObject[0])
      }
    } else {
      const serviceOrderId = formData.target.order.value

      if (!serviceOrderId) {
        showToast({
          type: 'error',
          message: 'Campo chamado requerido!',
        })
      } else {
        dispatch(getActiveBudget(serviceOrderId)).then(activeBudget => {
          setBudgetTemplate(activeBudget)
          showToast({
            type: 'success',
            message: 'Ação executada com sucesso',
          })
        })
      }
    }
    closeModal()
  }, [])

  const radioOptions = [
    {
      label: 'Importar de um modelo',
      value: 'importFromBudgetTemplate',
      key: 1,
    },
    {
      label: 'Importar de um chamado',
      value: 'importFromServiceOrder',
      key: 2,
    },
  ]

  return (
    <>
      <Dialog
        open
        maxWidth="xl"
        disableEscapeKeyDown
        onClose={closeModal}
        PaperComponent={PaperComponent}
        TransitionComponent={Transition}
        className={styles.dialog}
      >
        <DialogTitle className={styles.titleBlue} id="draggable-dialog-title-t">
          Orçamento para importar
        </DialogTitle>
        <DialogContent className={styles.container}>
          <form id="saveModel" onSubmit={handleSaveModel} className={styles.subcontainer}>
            <Grid>
              <Grid className={styles.inlineLabel}>
                <Typography className={styles.labels}>Opções</Typography>
                <Typography className={styles.required}>*</Typography>
              </Grid>
              <Grid className={styles.subjectWrapper}>
                <CustomRadio
                  control={control}
                  name="importType"
                  defaultValue="importFromBudgetTemplate"
                  options={radioOptions}
                  onChange={e => setValue('importType', e.target.value)}
                  register={register}
                />
              </Grid>

              <Grid>
                <Select
                  control={control}
                  className={styles.select}
                  budgetTemplate={budgetTemplates}
                  isBudgetTemplateField
                  name="template"
                  onChange={e => setValue('template', e.target.value)}
                  label="Modelo"
                  register={register}
                />
              </Grid>

              <Grid>
                <Select
                  control={control}
                  className={styles.select}
                  regions={serviceOrdersId}
                  name="order"
                  onChange={e => setValue('order', e.target.value)}
                  label="Chamado"
                  register={register}
                />
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions className={styles.dialogAction}>
          <Button className={styles.button} onClick={closeModal} color="red" variant="outlined">
            Cancelar
          </Button>
          <Button
            className={styles.button}
            form="saveModel"
            type="submit"
            color="primary"
            variant="contained"
          >
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

ImportBudgetTemplateForm.propTypes = {
  closeModal: PropTypes.func.isRequired,
}

export default ImportBudgetTemplateForm
