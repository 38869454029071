import React, { useEffect, useCallback } from 'react'
import { Grid } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import Button from '_/components/button'
import useToggle from '_hooks/use-toggle'

import Accordion from '../index'
import AccordionInfo from '../accordion-info'

import useStyles from '../styles'
import AddAgencyModal from './add-modal'
import { getAgencies } from '_/modules/agency/actions'

const AddBlockedAgency = ({ isProvider, isEditing, setValue, data, name }) => {
  const styles = useStyles()
  const dispatch = useDispatch()
  const [modalOpen, handleModal] = useToggle()

  useEffect(() => {
    dispatch(getAgencies({ pageSize: 1000 }))
  }, [])

  const handleSubmit = formValues => {
    const newAgencies = [...data, formValues]
    setValue(name, newAgencies)
  }

  const handleDeleteAgency = catObj => {
    setValue(
      name,
      data.filter(currentAgency => currentAgency.id !== catObj.id)
    )
  }

  const handleAddAgency = () => {
    handleModal()
  }

  const handleAccordionInfo = useCallback(() => {
    return (
      <Grid className={styles.userListAdd}>
        {data
          ?.sort((x, y) => (x.label < y.label ? -1 : 1))
          .map((agency, index) => (
            <AccordionInfo
              // eslint-disable-next-line react/no-array-index-key
              key={agency.id}
              isFirst={index === 0}
              type="agency"
              values={agency}
              isProvider={isProvider}
              isEditing={isEditing}
              handleDeleteValue={handleDeleteAgency}
            />
          ))}
      </Grid>
    )
  }, [data, isEditing])

  return (
    <Accordion
      id="imobiliarias-bloqueadas"
      title="Imobiliárias não atendidas"
      className={styles.containerAdd}
    >
      {handleAccordionInfo()}
      {isEditing && (
        <Button
          className={styles.buttonAdd}
          variant="contained"
          color="primary"
          onClick={handleAddAgency}
        >
          +Adicionar registro
        </Button>
      )}
      {modalOpen && (
        <AddAgencyModal
          modalOpen={modalOpen}
          handleModal={handleModal}
          handleSubmit={handleSubmit}
          available={data}
          title="Adicionar imobiliária"
          name={name}
        />
      )}
    </Accordion>
  )
}

export default AddBlockedAgency
