import React, { useCallback, useState, useEffect } from 'react'
import { Grid } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import CardMedia from '_components/card-media'
import { getCategories } from '_modules/categories/actions'
import { categoriesSelector } from '_modules/categories/selectors'
import DefaultImage from '_assets/images/default.png'

import InformationWrapper from '../information-wrapper'

import useStyles from './styles'
import { PROFILE_SECTION_FIELDS } from '_/views/agency/constants'

const AsideProfile = ({
  setFieldValue,
  values,
  touched,
  errors,
  profileName,
  isProvider,
  isEditing,
  ...props
}) => {
  const styles = useStyles()
  const [displayStatus, setDisplayStatus] = useState()

  const dispatch = useDispatch()
  const services = useSelector(categoriesSelector)

  const onChooseFile = useCallback(
    event => {
      const newFile = event.target.files[0]
      if (newFile) {
        setFieldValue(profileName, newFile)
      }
    },
    [profileName, setFieldValue]
  )

  const handleMouseOver = useCallback(() => {
    setDisplayStatus(true)
  }, [])
  const handleMouseOut = useCallback(() => {
    setDisplayStatus(false)
  }, [])

  const handleTextFieldProps = useCallback(
    ({ name, label, placeholder, mask }) => {
      return {
        id: name,
        isEditing,
        name,
        label,
        value: values[name],
        error: touched[name] && !!errors[name],
        helperText: (touched[name] && errors[name]) || '',
        placeholder,
        mask,
        ...props,
      }
    },
    [errors, isEditing, props, touched, values]
  )

  useEffect(() => {
    if (isProvider && !services.length) {
      dispatch(getCategories())
    }
  }, [dispatch, isProvider, services.length])

  return (
    <Grid component="aside" className={styles.aside}>
      <label
        onMouseOver={handleMouseOver}
        onFocus={handleMouseOver}
        onMouseOut={handleMouseOut}
        onBlur={handleMouseOut}
        htmlFor="upload-image"
        className={styles.addNewPictureContainer}
      >
        <input
          onChange={onChooseFile}
          id="upload-image"
          type="file"
          accept="image/*"
          key="input"
          className={styles.fileSelector}
          disabled={!isEditing}
        />
        {displayStatus ? (
          <Grid container justify="center" alignItems="center" className={styles.addNewPicture}>
            <AddIcon className={styles.addIcon} />
          </Grid>
        ) : (
          <CardMedia
            className={styles.image}
            file={values[profileName] || DefaultImage}
            alt="Imagem da imobiliária"
            showOnly
          />
        )}
      </label>

      {PROFILE_SECTION_FIELDS.map(field => (
        <InformationWrapper
          className={styles.information}
          key={field.name}
          {...handleTextFieldProps(field)}
          setFieldValue={setFieldValue}
          options={field?.options}
        />
      ))}
    </Grid>
  )
}

AsideProfile.propTypes = {
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      label: PropTypes.string,
    })
  ).isRequired,
  // TODO: Define this shape
  values: PropTypes.shape({
    mainServices: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.number),
      PropTypes.arrayOf(PropTypes.object),
    ]),
  }).isRequired,
  touched: PropTypes.shape({
    mainServices: PropTypes.string,
  }).isRequired,
  errors: PropTypes.shape({
    mainServices: PropTypes.string,
  }).isRequired,
  setFieldValue: PropTypes.func.isRequired,
  profileName: PropTypes.string.isRequired,
  isProvider: PropTypes.bool,
  handleEdit: PropTypes.func.isRequired,
  isEditing: PropTypes.bool.isRequired,
  setPercentChanged: PropTypes.func,
}

AsideProfile.defaultProps = {
  isProvider: false,
  setPercentChanged: () => {},
}

export default React.memo(AsideProfile)
